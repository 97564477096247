//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Default',
  data() {
    return {
      drawer: null,
      fetchUserInterval: null,
      checkReleaseInterval: null,
      showReleaseWarning: false,
      visibilityState: 'visible',
    };
  },
  head() {
    return {
      title: this.$store.state.app_bar_title,
    };
  },
  computed: {
    isAway() {
      return this.visibilityState === 'hidden';
    },
    userReloadTimeout() {
      return this.isAway ? 240000 : 30000;
    },
  },
  watch: {
    userReloadTimeout(newValue) {
      this.createUserReloadInterval();
    },
    isAway(newValue) {
      if (!newValue) {
        this.$auth.fetchUser();
      }
    },
  },
  mounted() {
    if (!this.fetchUserInterval) {
      this.checkRelease();
      this.createUserReloadInterval();
      if (!this.checkReleaseInterval) {
        this.checkReleaseInterval = setInterval(() => {
          this.checkRelease();
        }, 60000);
      }
    }
  },
  beforeMount() {
    document.addEventListener('visibilitychange', this.visibilityDidChange);
  },
  beforeDestroy() {
    if (this.fetchUserInterval) {
      clearInterval(this.fetchUserInterval);
      clearInterval(this.checkReleaseInterval);
    }
    document.removeEventListener('visibilitychange', this.visibilityDidChange);
  },
  methods: {
    createUserReloadInterval() {
      if (this.fetchUserInterval) {
        clearInterval(this.fetchUserInterval);
      }
      this.fetchUserInterval = setInterval(() => {
        if (this.$auth.loggedIn) {
          this.$auth.fetchUser();
        }
      }, this.userReloadTimeout);
    },
    visibilityDidChange() {
      this.visibilityState = document.visibilityState;
    },
    checkRelease() {
      if (process.env.NODE_ENV === 'production') {
        this.$axios
          .get('/last-release', {
            params: { t: Date.now() },
          })
          .then((res) => {
            this.showReleaseWarning =
              res.data.toString().trim() !==
              process.env.NUXT_ENV_LAST_RELEASE.trim();
          })
          .catch(() => {});
      }
    },
    refreshPage() {
      window.location.reload();
    },
  },
};
