import Vue from 'vue';
const mixins = {
  data() {
    return {
      lastOperatorNameReload: null,
    };
  },
  methods: {
    canSeeOperatorName(operatorId) {
      return (
        operatorId === this.$auth.user.id ||
        this.$auth.user.team.id !== 21 ||
        this.$auth.user.permissions.id >= 150
      );
    },

    operatorName(operatorId) {
      if (
        !this.$store.state.settings.operator_names ||
        !this.$store.state.settings.operator_names[operatorId]
      ) {
        if (
          !this.lastOperatorNameReload ||
          this.lastOperatorNameReload < new Date().getTime() - 1000 * 30
        ) {
          this.$store.dispatch('settings/force_reload_operator_names');
          this.lastOperatorNameReload = new Date().getTime();
        }
        return '';
      }
      return this.$store.state.settings.operator_names[operatorId]?.name || '';
    },
    countryName(countryCode) {
      if (Intl && Intl?.DisplayNames && Intl?.DisplayNames !== null) {
        const displayNames = new Intl.DisplayNames(this.$i18n.locale, {
          type: 'region',
        });
        try {
          return displayNames.of(countryCode);
        } catch (e) {}
      }
      return countryCode;
    },
    languageName(locale) {
      if (Intl && Intl?.DisplayNames && Intl?.DisplayNames !== null) {
        const displayNames = new Intl.DisplayNames(this.$i18n.locale, {
          type: 'language',
        });
        try {
          return displayNames.of(locale.replace('_', '-'));
        } catch (e) {}
      }
      return locale;
    },
    capitalize(string) {
      if (string === null || string === undefined || string === '') {
        return string;
      }
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    makeSelectItems(options) {
      return (options || []).map((item) => {
        return {
          text: this.capitalize(item).replace('_', ' '),
          value: item,
        };
      });
    },
  },
};

if (!Vue.__operator_mixin__) {
  Vue.__operator_mixin__ = true;
  Vue.mixin(mixins);
}
