import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2584e2bf = () => interopDefault(import('../pages/expired.vue' /* webpackChunkName: "pages/expired" */))
const _4003d0ae = () => interopDefault(import('../pages/history.vue' /* webpackChunkName: "pages/history" */))
const _93753cfa = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _3bed64da = () => interopDefault(import('../pages/message-reports.vue' /* webpackChunkName: "pages/message-reports" */))
const _27260abc = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _7273881d = () => interopDefault(import('../pages/operator-login-history.vue' /* webpackChunkName: "pages/operator-login-history" */))
const _1ac6fe2c = () => interopDefault(import('../pages/operators/index.vue' /* webpackChunkName: "pages/operators/index" */))
const _d1e850c4 = () => interopDefault(import('../pages/permissions.vue' /* webpackChunkName: "pages/permissions" */))
const _5716ff33 = () => interopDefault(import('../pages/poke-queue.vue' /* webpackChunkName: "pages/poke-queue" */))
const _e53045d2 = () => interopDefault(import('../pages/profilesets/index.vue' /* webpackChunkName: "pages/profilesets/index" */))
const _95dd11aa = () => interopDefault(import('../pages/queue.vue' /* webpackChunkName: "pages/queue" */))
const _7f6a74ff = () => interopDefault(import('../pages/skiplog.vue' /* webpackChunkName: "pages/skiplog" */))
const _bd9ea88e = () => interopDefault(import('../pages/stats.vue' /* webpackChunkName: "pages/stats" */))
const _54ba77c9 = () => interopDefault(import('../pages/tfa.vue' /* webpackChunkName: "pages/tfa" */))
const _4746b99c = () => interopDefault(import('../pages/profilesets/profile-text.vue' /* webpackChunkName: "pages/profilesets/profile-text" */))
const _64650195 = () => interopDefault(import('../pages/conversations/_id.vue' /* webpackChunkName: "pages/conversations/_id" */))
const _16169b94 = () => interopDefault(import('../pages/operators/_id.vue' /* webpackChunkName: "pages/operators/_id" */))
const _66a8393f = () => interopDefault(import('../pages/profilesets/_id.vue' /* webpackChunkName: "pages/profilesets/_id" */))
const _26c48328 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/expired",
    component: _2584e2bf,
    name: "expired"
  }, {
    path: "/history",
    component: _4003d0ae,
    name: "history"
  }, {
    path: "/login",
    component: _93753cfa,
    name: "login"
  }, {
    path: "/message-reports",
    component: _3bed64da,
    name: "message-reports"
  }, {
    path: "/messages",
    component: _27260abc,
    name: "messages"
  }, {
    path: "/operator-login-history",
    component: _7273881d,
    name: "operator-login-history"
  }, {
    path: "/operators",
    component: _1ac6fe2c,
    name: "operators"
  }, {
    path: "/permissions",
    component: _d1e850c4,
    name: "permissions"
  }, {
    path: "/poke-queue",
    component: _5716ff33,
    name: "poke-queue"
  }, {
    path: "/profilesets",
    component: _e53045d2,
    name: "profilesets"
  }, {
    path: "/queue",
    component: _95dd11aa,
    name: "queue"
  }, {
    path: "/skiplog",
    component: _7f6a74ff,
    name: "skiplog"
  }, {
    path: "/stats",
    component: _bd9ea88e,
    name: "stats"
  }, {
    path: "/tfa",
    component: _54ba77c9,
    name: "tfa"
  }, {
    path: "/profilesets/profile-text",
    component: _4746b99c,
    name: "profilesets-profile-text"
  }, {
    path: "/conversations/:id?",
    component: _64650195,
    name: "conversations-id"
  }, {
    path: "/operators/:id",
    component: _16169b94,
    name: "operators-id"
  }, {
    path: "/profilesets/:id",
    component: _66a8393f,
    name: "profilesets-id"
  }, {
    path: "/",
    component: _26c48328,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
