export default {
  interests: {
    title: 'Zainteresowania',
    relationship: 'Związek',
    onenightstand: 'Przygoda na jedną noc',
    kissing: 'Całowanie',
    hugging: 'Przytulanie',
    massages: 'Masaże',
    lingerie: 'Bielizna',
    sex: 'Sex',
    safesex: 'Bezpieczny seks',
    publicsex: 'Seks w miejscu publicznym',
    oralsex: 'Seks oralny',
    analsex: 'Seks analny',
    sextoys: 'Zabawki erotyczne',
    masturbating: 'Masturbacja',
    exchangingpictures: 'Wymiana zdjęć',
    threesome: 'Trójkąt',
    bondage: 'Zniewolenie',
    sadomasochism: 'Sadomasochizm',
    gym: 'Siłownia',
    sports: 'Sport',
    dancing: 'Taniec',
    travelling: 'Podróżowanie',
    adventure: 'Przygoda',
    party: 'Impreza',
    tattoos: 'Tatuaże',
    music: 'Muzyka',
    movies: 'Filmy',
    videogames: 'Gry wideo',
    politics: 'Polityka',
    outdoors: 'Outdoor',
    gardening: 'Ogrodnictwo',
    hiking: 'Turystyka piesza',
    biking: 'Jazda na rowerze',
    reading: 'Czytanie',
    animals: 'Zwierzęta',
    beach: 'Plaża',
    art: 'Sztuka',
    photography: 'Fotografia',
    theater: 'Teatr',
    shopping: 'Zakupy',
    fashion: 'Moda',
    astrology: 'Astrologia',
    cooking: 'Gotowanie',
    foodie: 'Smakosz',
    bbq: 'BBQ',
    sushi: 'Sushi',
    vegetarian: 'Wegetarianizm',
    wine: 'Wino',
    beer: 'Piwo',
    tea: 'Herbata',
    coffee: 'Kawa',
  },

  FICTIONAL_PROFILE_FETISH: 'Fikcyjny fetysz profilu: {name}',
  REQUEST_AWAY_TIME: 'Czas poza czatem',
  REQUEST_AWAY_TIME_SUB: 'Uzyskaj 2 minuty',
  REQUEST_AWAY_TIME_WHY:
    'Dlaczego potrzebujesz czasu poza czatem? Wszystkie prośby są zgłaszane trenerom.',
  REQUEST_AWAY_TIME_BTN: 'Uzyskaj 2 minuty',
  '{count} Dostępnych czatów': '(1) dostępny czat | ({count}) dostępnych czatów',
  fictional_text_warning:
    'Użytkownik może mówić o fikcyjnych profilach. Prosimy o kontakt z administratorem.',
  chat_back_to: {
    index: 'Powrót do panelu głównego',
    messages: 'Wróć do @:(chat_overview)',
    history: 'Wróc do @:(message_history)',
    message_reports: 'Wróć do @:(message_reports)',
    skiplog: 'wróć do @:(skiplog)',
  },
  SKIP_MESSAGE_REASON_TITLE: 'Podaj powód pominięcia',
  SKIP_REASON: 'Pomiń powód',
  SKIP_REASON_HINT: 'Wyjaśnij, dlaczego musisz pominąć tę wiadomość',
  SKIP_MESSAGE_CANCEL: 'Wróć do konwersacji',
  SKIP_MESSAGE_CONTINUE: 'Pomiń wiadomość',
  'Report message': 'Zgłoś wiadomość',
  REPORT_MESSAGE_BTN: 'Zgłoś wysłanie wiadomości',
  REPORT_MESSAGE: 'Zgłoś wiadomość',
  REPORT_NOTE_HINT: "Wyjaśnij, co jest nie tak z tą wiadomością",
  REPORT_REASON_LABEL: 'Powód',
  REPORT_NOTE_LABEL: 'Uwagi',
  chat_overview: 'Przegląd czatu',
  skiplog: 'Dziennik pominięć',
  message_reports: 'Zgłoszenia wiadomości',
  'Number of messages in conversation': 'Liczba wiadomości w konwersacji',
  'Filter conversations': 'Filtruj rozmowy',
  'No minimum': 'Brak minimum',
  'No maximum': 'Brak maksimum',
  'All statuses': 'Wszystkie statusy',
  'Operator status': 'Status operatora',
  'Paid users': 'Płatni użytkownicy',
  'Free users': 'Darmowi użytkownicy',
  'Member type': 'Typ użytkownika',
  'Number of pokes after last message': 'Liczba zaczepek po ostatniej wiadomości',
  'All users': 'Wszyscy użytkownicy',
  Minimum: 'Minimum',
  Maximum: 'Maksimum',
  operator_status: {
    unread: 'Nieprzeczytane',
    unanswered: 'Bez odpowiedzi',
    ignored: 'Ignorowane',
    answered: 'Odpowiedziane',
  },

  Length: 'Długość',
  Message: 'Wiadomość',
  Answered: 'Odpowiedziane',
  SKIP_DATE: 'Pominięte',
  SENT_DATE: 'Wysłane',
  'Filter messages': 'Filtruj wiadomości',
  'All message types': 'Wszystkie typy wiadomości',
  'All operators': 'Wszyscy operatorzy',
  'Message type': 'Typ wiadomości',
  message_history: 'Historia wiadomości',
  'load many': 'załaduj wiele',
  SKIP_MESSAGE_WARNING_TITLE: 'Anuluj wysyłanie wiadomości',
  SKIP_MESSAGE_WARNING:
    'Czy na pewno chcesz kontynuować bez wysyłania wiadomości?',
  SKIP_MESSAGE_SEND: 'Wyślij wiadomość teraz',
  SKIP_MESSAGE_DELETE: 'Usuń wiadomość',
  Paused: 'Wstrzymano',
  Permissions: 'Uprawnienia',
  operator_name: 'Imię',
  open_conversations: 'Otwarte rozmowy',
  no_open_conversations: 'Brak otwartych rozmów / oczekujących w kolejce',
  logged_in_operators: 'Operatorzy czatu',
  language: 'Język',
  cnt_pokes: '# zaczepki',
  cnt_messages: '# wiadomości',
  cnt_operators: '# operatorzy',

  oldest_message: 'Najstarsza wiadomość',
  newest_message: 'Najnowsza wiadomość',
  QUEUE_STATS: 'Statystyki kolejki',
  SOMEONE_ELSE_ANSWERING: '{name} odpowiada na ten czat.',
  SAVE: 'Zapisz',
  SET_ALERT: 'Ustaw alert',
  AT_CUSTOMER_PROFILE: 'Na profilu klienta',
  STOP_POKES: 'Zatrzymaj zaczepki',
  IGNORE: 'Ignoruj czat',
  UNTIL_NEXT_MESSAGE: 'Do następnej wiadomości',
  IGNORE_CONVERSATION_WARNING:
    "Użytkownik nie otrzyma odpowiedzi! Używaj tego tylko w przypadku nadużyć i nikt nie powinien na to odpowiadać (na przykład osoby niepełnoletnie)",
  stats: {
    total: 'Wszyscy operatorzy ogółem',
    message_type: 'Typ wiadomości',
    count_messages: '# wiadomości',
    count_messages_answered: '# wiadomości z odpowiedzią',
    count_messages_answered_percentage: 'Procent z odpowiedzią',
    types: {
      starter: 'Początek rozmowy',
      reply: 'Pierwsza odpowiedź na wiadomość od klienta (lub po zdjęciu)',
      extra_reply: 'Dodatkowe odpowiedzi',
      poke: 'Zaczepka',
      extra_poke: 'Dodatkowa zaczepka',
      starter_photo: 'Rozpoczęcie rozmowy (zdjęcie)',
      reply_photo: 'Pierwsza odpowiedź na wiadomość od klienta (zdjęcie)',
      extra_reply_photo: 'Dodatkowe odpowiedzi (zdjęcie)',
      poke_photo: 'Zaczepka (zdjęcie)',
      extra_poke_photo: 'Dodatkowa zaczepka (zdjęcie)',
      starter_gift: 'Rozpoczęcie rozmowy (prezent)',
      reply_gift: 'Pierwsza odpowiedź na wiadomość od klienta (prezent)',
      extra_reply_gift: 'Dodatkowe odpowiedzi (prezent)',
      poke_gift: 'Zaczepka (prezent)',
      extra_poke_gift: 'Dodatkowa zaczepka (prezent)',
    },
  },

  Stats: 'Statystyki',
  OTHER_CHAT_COUNT: '1 czat | {count} czatów',
  FREE_MEMBER: 'Użytkownik testowy',
  PREMIUM_MEMBER: 'Użytkownik Premium',
  USER_CREDITS: '1 pozostały kredyt | {count} pozostałych kredytów',
  LAST_MESSAGE: '1 wiadomość, {date} | {num_messages} wiadomości, ostatnia {date}',
  MEMBER_SINCE: 'Użytkownik od {date}',
  MESSAGE_COUNT: '1 wiadomość | {count} wiadomości',
  PHOTOMESSAGE_COUNT: '1 zdjęcie | {count} zdjęć',
  INVISIBLE_PHOTOMESSAGE_COUNT: '1 niewidoczne zdjęcie | {count} niewidocznych zdjęć',
  MESSAGE_SINCE: 'Pierwsza wiadomość: {date}',
  today: 'Dzisiaj',
  yesterday: 'Wczoraj',
  TFA_LOGIN:
    'Wprowadź 6 cyfr z aplikacji 2FA (Google Authenticater lub Authy)',
  SEND_POKE: 'Wyślij {name} zaczepkę!',
  AGE_DISPLAY: '{age} lat',
  'Empty chat, send a message first.': 'Pusty czat, najpierw wyślij wiadomość.',
  'Load more messages': 'Załaduj więcej wiadomości',
  loading_messages: 'Wiadomości są ładowane...',
  SEND_MESSAGE_PLACEHOLDER: 'Wyślij wiadomość',
  SEND_BTN: 'Wyślij',
  LOCKED_BY: 'Wiadomość zablokowana przez: {name}',
  CUSTOMER: 'KLIENT',
  PLAYER: 'GRACZ',
  'Open chat queue': 'Otwórz kolejkę czatów',
  'Open poke queue': 'Otwórz kolejkę zaczepek',
  'Poke Queue': 'Kolejka zaczepek',
  Logout: 'Wylogowanie',
  'Waiting for new message': 'Oczekiwanie na nową wiadomość',
  'Message Queue': 'Kolejka wiadomości',
  LOGIN_BLOCKED: 'Zbyt wiele nieudanych prób, spróbuj ponownie za 10 minut.',
  LOGIN_FAILED: 'Logowanie nie powiodło się, spróbuj ponownie',
  male: 'mężczyzna',
  female: 'kobieta',
  personal: 'Osobiste',
  birthday: 'Urodziny',
  age: 'Wiek',
  city: 'Miasto',
  sports: 'Sport',
  job: 'Praca',
  likes: 'Lubi',
  hobbies: 'Hobby',
  children: 'Dzieci',
  pets: 'Zwierzęta',
  important: 'Ważne',
  piercings: 'Piercing',
  reason: 'Powód',
  other: 'Inne',
  smokes: 'Pali',
  tattoos: 'Tatuaże',
  eye_color: 'Kolor oczu',
  hair_color: 'Kolor włosów',
  reletionship_status: 'Związek',
  yes: 'Tak',
  no: 'Nie',
  blue: 'Niebieski',
  brown: 'Brązowy',
  green: 'Zielony',
  gray: 'Szary',
  hazel: 'Piwne',
  bald: 'Łysy',
  black: 'Czarne',
  blond: 'Blond',
  'dark blond': 'Ciemny blond',
  'dark brown': 'Ciemny brąz',
  red: 'Czerwone',
  divorced: 'Rozwiedziony',
  'living together': 'Mieszkanie razem',
  married: 'Zamężna/żonaty',
  single: 'Singiel',
  unmarried: 'Niezamężna/nieżonaty',
  widowed: 'Wdowa/wdowiec',
  sometimes: 'Czasami',
  stopping: 'Próba zatrzymania',
  hetero: 'Hetero',
  gay: 'Gej',
  lesbian: 'Lesbijka',
  bi: 'Biseksualny',
  length: 'Wzrost',
  weight: 'Waga',
};
