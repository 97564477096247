export default {
  interests: {
    title: 'Intereses',
    relationship: 'Relación',
    onenightstand: 'Líos de una noche',
    kissing: 'Besarse',
    hugging: 'Abrazarse',
    massages: 'Masajes',
    lingerie: 'Lencería',
    sex: 'Sexo',
    safesex: 'Sexo seguro',
    publicsex: 'Sexo en público',
    oralsex: 'Sexo oral',
    analsex: 'Sexo anal',
    sextoys: 'Juguetes sexuales',
    masturbating: 'Masturbación',
    exchangingpictures: 'Intercambiar fotos',
    threesome: 'Tríos',
    bondage: 'Bondage',
    sadomasochism: 'Sadomasoquismo',
    gym: 'Gimnasio',
    sports: 'Deporte',
    dancing: 'Bailar',
    travelling: 'Viajar',
    adventure: 'Aventura',
    party: 'Fiesta',
    tattoos: 'Tatuajes',
    music: 'Música',
    movies: 'Películas',
    videogames: 'Videojuegos',
    politics: 'Política',
    outdoors: 'Actividades al aire libre',
    gardening: 'Jardinería',
    hiking: 'Senderismo',
    biking: 'Ciclismo',
    reading: 'Leer',
    animals: 'Animales',
    beach: 'Playa',
    art: 'Arte',
    photography: 'Fotografía',
    theater: 'Teatro',
    shopping: 'Shopping',
    fashion: 'Moda',
    astrology: 'Astrología',
    cooking: 'Cocinar',
    foodie: 'Foodie',
    bbq: 'BBQ',
    sushi: 'Sushi',
    vegetarian: 'Cocina vegetariana',
    wine: 'Vino',
    beer: 'Cerveza',
    tea: 'Té',
    coffee: 'Café',
  },

  FICTIONAL_PROFILE_FETISH: 'Fetiche del perfil ficticio: {name}',
  REQUEST_AWAY_TIME: 'Fuera del tiempo de chat',
  REQUEST_AWAY_TIME_SUB: 'Consigue 2 minutos',
  REQUEST_AWAY_TIME_WHY:
    '¿Por qué necesitas tiempo fuera de este chat? Todas las solicitudes se informan a los coaches.',
  REQUEST_AWAY_TIME_BTN: 'Consigue 2 minutos',
  '{count} Chats disponibles':
    '(1) chat disponible | ({count}) chats disponibles',
  fictional_text_warning:
    'El usuario podría estar hablando de perfiles ficticios. Por favor contacte al encargado.',
  chat_back_to: {
    index: 'Volver al panel principal',
    messages: 'Volver a @:(chat_overview)',
    history: 'Volver a @:(message_history)',
    message_reports: 'Volver a @:(message_reports)',
    skiplog: 'Volver a @:(skiplog)',
  },
  SKIP_MESSAGE_REASON_TITLE: 'Ingrese el motivo para saltar',
  SKIP_REASON: 'Omitir razón',
  SKIP_REASON_HINT: 'Explica por qué necesitas saltar este mensaje.',
  SKIP_MESSAGE_CANCEL: 'Volver a la conversación',
  SKIP_MESSAGE_CONTINUE: 'Saltar mensaje',
  'Report message': 'Reportar mensaje',
  REPORT_MESSAGE_BTN: 'Mandar reporte del mensaje',
  REPORT_MESSAGE: 'Reportar mensaje',
  REPORT_NOTE_HINT: 'Explica qué hay de malo en este mensaje.',
  REPORT_REASON_LABEL: 'Razón',
  REPORT_NOTE_LABEL: 'Notas',
  chat_overview: 'Vista general del chat',
  skiplog: 'Skiplog',
  message_reports: 'Informes de mensajes',
  'Number of messages in conversation': 'Número de mensajes en la conversación',
  'Filter conversations': 'Filtrar conversaciones',
  'No minimum': 'Sin mínimo',
  'No maximum': 'Sin máximo',
  'All statuses': 'Todos los estados',
  'Operator status': 'Estado del operador',
  'Paid users': 'Usuarios de pago',
  'Free users': 'Usuarios gratuitos',
  'Member type': 'Tipo de miembro',
  'Number of pokes after last message':
    'Número de toques después del último mensaje',
  'All users': 'Todos los usuarios',
  Minimum: 'Mínimo',
  Maximum: 'Máximo',
  operator_status: {
    unread: 'No leído',
    unanswered: 'Sin respuesta',
    ignored: 'Ignorado',
    answered: 'Respondido',
  },

  Length: 'Longitud',
  Message: 'Mensaje',
  Answered: 'Respondido',
  SKIP_DATE: 'Saltado',
  SENT_DATE: 'Enviado',
  'Filter messages': 'Filtrar mensajes',
  'All message types': 'Todo tipo de mensajes',
  'All operators': 'Todos los operadores',
  'Message type': 'Tipo de mensaje',
  message_history: 'Historial de mensajes',
  'load many': 'Cargar muchos',
  SKIP_MESSAGE_WARNING_TITLE: 'Anular envío del mensaje',
  SKIP_MESSAGE_WARNING:
    '¿Estás seguro de que quieres continuar sin enviar tu mensaje?',
  SKIP_MESSAGE_SEND: 'Enviar mensaje ahora',
  SKIP_MESSAGE_DELETE: 'Eliminar mensaje',
  Paused: 'Pausado',
  Permissions: 'Permisos',
  operator_name: 'Nombre',
  open_conversations: 'Conversaciones abiertas',
  no_open_conversations: 'No hay conversaciones abiertas / esperando en cola',
  logged_in_operators: 'Operadores de chat',
  language: 'Idioma',
  cnt_pokes: '# toques',
  cnt_messages: '# mensaje',
  cnt_operators: '# operadores',

  oldest_message: 'Mensaje más antiguo',
  newest_message: 'Último mensaje',
  QUEUE_STATS: 'Estadísticas de cola',
  SOMEONE_ELSE_ANSWERING: '{name} está respondiendo este chat.',
  SAVE: 'Guardar',
  SET_ALERT: 'Establecer alerta',
  AT_CUSTOMER_PROFILE: 'En el perfil del cliente',
  STOP_POKES: 'Detener toques',
  IGNORE: 'Ignorar chat',
  UNTIL_NEXT_MESSAGE: 'Hasta el próximo mensaje',
  IGNORE_CONVERSATION_WARNING:
    '¡El usuario no recibirá respuesta! Utilice esto solo en caso de abuso y nadie debe responder esto (por ejemplo menores)',
  stats: {
    total: 'Total todos los operadores',
    message_type: 'Tipo de mensaje',
    count_messages: '# mensajes',
    count_messages_answered: '# mensajes con respuesta',
    count_messages_answered_percentage: 'Porcentaje con respuesta',
    types: {
      starter: 'Inicador de conversación',
      reply: 'Primera respuesta al mensaje del cliente (o después de la foto)',
      extra_reply: 'Respuestas adicionales',
      poke: 'Toque',
      extra_poke: 'Toque adicional',
      starter_photo: 'Iniciador de conversación (foto)',
      reply_photo: 'Primera respuesta al mensaje de los clientes (foto)',
      extra_reply_photo: 'Respuestas adicionales (foto)',
      poke_photo: 'Toque (foto)',
      extra_poke_photo: 'Toque adicional (foto)',
      starter_gift: 'Iniciador de conversación (gift)',
      reply_gift: 'Primera respuesta al mensaje de los clientes (gift)',
      extra_reply_gift: 'Respuestas adicionales (gift)',
      poke_gift: 'Toque (gift)',
      extra_poke_gift: 'Toque adicional (gift)',
    },
  },

  Stats: 'Estadísticas',
  OTHER_CHAT_COUNT: '1 chat | {count} chats',
  FREE_MEMBER: 'Miembro de prueba',
  PREMIUM_MEMBER: 'Miembro premium',
  USER_CREDITS: '1 crédito restante | {count} créditos restantes',
  LAST_MESSAGE:
    '1 mensaje, {date} | {num_messages} mensajes, el último el {date}',
  MEMBER_SINCE: 'Miembro desde {date}',
  MESSAGE_COUNT: '1 mensaje | {count} mensajes',
  PHOTOMESSAGE_COUNT: '1 foto | {count} fotos',
  INVISIBLE_PHOTOMESSAGE_COUNT: '1 foto invisible | {count} fotos invisibles',
  MESSAGE_SINCE: 'Primer mensaje: {date}',
  today: 'Hoy',
  yesterday: 'Ayer',
  TFA_LOGIN:
    'Ingrese los 6 dígitos desde su aplicación 2FA (Google Authenticater o Authy)',
  SEND_POKE: '¡Enviar a {name} un toque!',
  AGE_DISPLAY: '{age} años',
  'Empty chat, send a message first.': 'Chat vacío, envíe un mensaje primero.',
  'Load more messages': 'Cargar más mensajes',
  loading_messages: 'Los mensajes se están cargando...',
  SEND_MESSAGE_PLACEHOLDER: 'Enviar mensaje',
  SEND_BTN: 'Enviar',
  LOCKED_BY: 'Mensaje bloqueado por: {name}',
  CUSTOMER: 'CLIENTE',
  PLAYER: 'JUGADOR',
  'Open chat queue': 'Abrir cola de chat',
  'Open poke queue': 'Abrir cola de toques',
  'Poke Queue': 'Cola de toques',
  Logout: 'Cerrar sesión',
  'Waiting for new message': 'Esperando nuevo mensaje',
  'Message Queue': 'Cola de mensajes',
  LOGIN_BLOCKED:
    'Demasiados intentos fallidos. Vuelve a intentarlo en 10 minutos.',
  LOGIN_FAILED: 'Error al iniciar sesión, inténtalo de nuevo',
  male: 'masculino',
  female: 'femenino',
  personal: 'Personal',
  birthday: 'Fecha de nacimiento',
  age: 'Edad',
  city: 'Ciudad',
  sports: 'Deportes',
  job: 'TRabajo',
  likes: 'Me gusta',
  hobbies: 'Hobbies',
  children: 'Hijos',
  pets: 'Mascotas',
  important: 'Importante',
  piercings: 'Piercings',
  reason: 'Razones',
  other: 'Otros',
  smokes: 'Tabaco',
  tattoos: 'Tattoos',
  eye_color: 'Color de ojos',
  hair_color: 'Color de pelo',
  reletionship_status: 'Relación',
  yes: 'Sí',
  no: 'No',
  blue: 'Azul',
  brown: 'Marrón',
  green: 'Verde',
  gray: 'Gris',
  hazel: 'Marrón',
  bald: 'Calvo',
  black: 'Negro',
  blond: 'Rubio',
  'dark blond': 'Rubio oscuro',
  'dark brown': 'Rubio claro',
  red: 'Pelirrojo',
  divorced: 'Divorciado/a',
  'living together': 'Viviendo con alguien',
  married: 'Casado/a',
  single: 'Soltero/a',
  unmarried: 'No casado/a',
  widowed: 'Viudo/a',
  sometimes: 'A veces',
  stopping: 'Intentando parar',
  hetero: 'Hetero',
  gay: 'Gay',
  lesbian: 'Lesbiana',
  bi: 'Bisexual',
  length: 'Altura',
  weight: 'Peso',
};
